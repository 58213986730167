import {
  AffiliationOverviewFragment,
  CompanyFragment,
  useGetAllAffiliationOverviewQuery,
} from '@generated';
import { useMemo } from 'react';
import { useAuthenticatedUser } from 'src/services/authentication/hooks';
import { extractNonNullItems } from 'src/utils/extractNonNullItems';

interface UseGetUserAffiliationsReturn {
  affiliations: AffiliationOverviewFragment[];
  isLoading: boolean;
  companies: CompanyFragment[];
  hasNoAffiliations: boolean;
}

export const useGetUserAffiliations = (): UseGetUserAffiliationsReturn => {
  const { user } = useAuthenticatedUser();
  const customerUuid = user?.customer?.uuid ?? '';

  const {
    data: allAffiliationsData,
    loading: isLoading,
  } = useGetAllAffiliationOverviewQuery({
    skip: !customerUuid,
    variables: customerUuid ? { customerUuid } : undefined,
  });

  const affiliations = useMemo(() => {
    // filtering empty items from list like: [null, {}, {}] - if something breaks on the facade for one of the affiliations
    return extractNonNullItems(allAffiliationsData?.affiliations?.nodes).sort(
      (a, b) => {
        const aName = a?.company?.name ?? '';
        const bName = b?.company?.name ?? '';
        return aName.localeCompare(bName);
      }
    );
  }, [allAffiliationsData]);

  const companies = useMemo(() => {
    if (!affiliations || affiliations.length === 0) {
      return [];
    }
    return affiliations.map((affiliation) => affiliation.company);
  }, [affiliations]);

  const hasNoAffiliations = affiliations.length === 0;

  return {
    affiliations,
    isLoading,
    companies,
    hasNoAffiliations,
  };
};
