import { useGetFinancingSummaryQuery } from '@generated';
import { useGetFinancingObjects } from 'src/hooks/queries/useGetFinancingObjects';
import { useGetUserAffiliations } from 'src/hooks/useGetUserAffiliations';

interface UseFinancingDataArgs {
  proposalRequestUuid?: string;
}

export const useFinancingData = ({
  proposalRequestUuid,
}: UseFinancingDataArgs) => {
  const {
    affiliations,
    isLoading: isAffiliationLoading,
  } = useGetUserAffiliations();

  const affiliationIds = affiliations.map((a) => a.uuid);

  const {
    isLoading: isFinancingObjectsLoading,
    financingObjects,
    refetchFinancingObjects,
  } = useGetFinancingObjects(affiliationIds);

  const relevantFinancingObjectUuid = financingObjects.find(
    (f) => f?.proposal_request?.uuid === proposalRequestUuid
  )?.uuid;

  const {
    data: relevantFinancingObject,
    loading: isRelevantFinancingObjectLoading,
  } = useGetFinancingSummaryQuery({
    skip: !relevantFinancingObjectUuid,
    variables: {
      uuid: relevantFinancingObjectUuid ?? '',
    },
  });

  const affiliationUuid =
    relevantFinancingObject?.financingObject?.proposal_request?.affiliation
      ?.uuid;

  const companyName =
    relevantFinancingObject?.financingObject?.proposal_request?.affiliation
      ?.company?.name;

  return {
    companyName,
    relevantFinancingObject,
    isLoading:
      isFinancingObjectsLoading ||
      isRelevantFinancingObjectLoading ||
      isAffiliationLoading,
    affiliations,
    financingObjects,
    refetchFinancingObjects,
    affiliationUuid,
  };
};
