import {
  AffiliationOverviewFragment,
  GetAllFinancingObjectsQuery,
  SecondariesRequest,
} from '@generated';

export enum FinancingReason {
  Liquidity = 'liquidity',
  Exercise = 'exercise',
}

export type AllFinancingObjectsItem = NonNullable<
  NonNullable<GetAllFinancingObjectsQuery['financingObjects']>['nodes']
>[number];

export enum FinancingBreadcrumbs {
  Requests = 'Requests',
  Back = 'Back',
}

export enum FinancingProduct {
  Financing = 'financing',
  Loan = 'loan',
  Secondaries = 'secondaries',
}

export interface EnhancedSecondariesRequest
  extends Omit<SecondariesRequest, 'affiliation'> {
  affiliation: AffiliationOverviewFragment | undefined;
}
