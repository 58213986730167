import * as Sentry from '@sentry/remix';
import { analytics } from 'src/services/analytics/analyticsService';
import { clearNilProperties } from 'src/utils/objects';

// Standardized trait shape expected by Segment
export interface IdentifyTraits {
  advisor?: string | undefined | null;
  company?: {
    name: string;
    id: string | null | undefined;
    status?: string | undefined | null;
  } | null;
  companies?: string | undefined | null;
  nickname?: string | undefined | null;
}

export const identify = (
  user: {
    uuid?: string | undefined | null;
    email?: string | undefined | null;
    first_name?: string | undefined | null;
    last_name?: string | undefined | null;
  },
  otherTraits: IdentifyTraits = {}
) => {
  const traits = clearNilProperties({
    advisor: otherTraits.advisor,
    all_companies: otherTraits.companies,
    company: otherTraits.company,
    email: user?.email,
    name: [user?.first_name, user?.last_name].filter(Boolean).join(' ') || null,
    nickname: otherTraits.nickname,
  });

  if (!user?.uuid) {
    return;
  }

  Sentry.setUser({
    id: user.uuid,
    email: user.email || undefined,
    username: traits.nickname || undefined,
  });

  return analytics.identify(user.uuid, traits);
};

export const trackGlobalEventClicked = (name: string) => {
  analytics.track('Global tracking event', {
    pathname: window.location.pathname,
    name: name,
  });
};
